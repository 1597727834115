<template>
  <div class="row">
    <div class="col-12 mb-3">
      <h5>
        <div class="form-subheader"><span>Vigência do Contrato</span></div>
      </h5>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Início da Vigência</label>
      <p>{{ formatDateLocal(quotation.rental_guarantee ? quotation.rental_guarantee.beginning_term : '---') }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Término da Vigência</label>
      <p>{{ formatDateLocal(quotation.rental_guarantee ? quotation.rental_guarantee.end_term : '---') }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Dias de Vigência</label>
      <p>{{ quotation.rental_guarantee.effective_days }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Cobertura/Garantia - Verba declarada</label>
      <p v-for="(cw, index) in quotation.coverage_warranties" :key="index">{{ cw.title }} - {{ formatCurrency(cw.value) }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Coberturas Adicionais</label>
      <p>Danos ao imóvel: {{ quotation.rental_guarantee.property_damage ? 'Sim' : '--' }}</p>
      <p>Multas contratuais: {{ quotation.rental_guarantee.contractual_fines ? 'Sim' : '--' }}</p>
      <p>Pintura externa: {{ quotation.rental_guarantee.external_painting ? 'Sim' : '--' }}</p>
      <p>Pintura interna: {{ quotation.rental_guarantee.internal_painting ? 'Sim' : '--' }}</p>
    </div>

  </div>
</template>

<script>
import commomsHelper from "@/mixins/commomsHelper";
import dateFormatter from "@/mixins/dateFormatter";
import numericFormatter from "@/mixins/numericFormatter";
import translatePartnerTypes from "@/enums/translate/partnerTypes";

export default {
  name: "ContractTerm",
  props: {
    quotation: {
      rental_guarantee: Object,
      coverage_warranties: Array
    }
  },
  mixins: [commomsHelper, dateFormatter, numericFormatter],
  data: () => ({
    translatePartnerTypes: translatePartnerTypes
  })
};
</script>

<style></style>
