export default {
    "Tribunal de Justiça do Acre": 1,
    "Tribunal de Justiça do Alagoas": 2,
    "Tribunal de Justiça do Amapá": 3,
    "Tribunal de Justiça do Amazonas": 4,
    "Tribunal de Justiça da Bahia": 5,
    "Tribunal de Justiça do Ceará": 6,
    "Tribunal de Justiça do Distrito Federal e dos Territórios": 7,
    "Tribunal de Justiça do Espírito Santo": 8,
    "Tribunal de Justiça de Goiás": 9,
    "Tribunal de Justiça do Maranhão": 10,
    "Tribunal de Justiça do Mato Grosso": 11,
    "Tribunal de Justiça do Mato Grosso do Sul": 12,
    "Tribunal de Justiça de Minas Gerais": 13,
    "Tribunal de Justiça do Pará": 14,
    "Tribunal de Justiça da Paraíba": 15,
    "Tribunal de Justiça do Paraná": 16,
    "Tribunal de Justiça de Pernambuco": 17,
    "Tribunal de Justiça do Piauí": 18,
    "Tribunal de Justiça do Rio de Janeiro": 19,
    "Tribunal de Justiça do Rio Grande do Norte": 20,
    "Tribunal de Justiça do Rio Grande do Sul": 21,
    "Tribunal de Justiça de Rondônia": 22,
    "Tribunal de Justiça de Roraima": 23,
    "Tribunal de Justiça de Santa Catarina": 24,
    "Tribunal de Justiça de São Paulo": 25,
    "Tribunal de Justiça de Sergipe": 26,
    "Tribunal de Justiça de Tocantins": 27,
};
