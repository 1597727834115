<template>
  <div class="accordion" role="tablist">
    <b-card
      :key="lineIndex"
      v-for="(lineError, lineIndex) in groupedErrors"
      no-body
      class="mb-1"
    >
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button
          block
          v-b-toggle="'accordion-' + lineIndex"
          variant="outline-primary"
          >Linha {{ Number(lineIndex) + 1 }}</b-button
        >
      </b-card-header>
      <b-collapse
        :id="'accordion-' + lineIndex"
        visible
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-card-body>
          <template v-for="(errors, propertyError) in lineError">
            <b-card-text class="font-weight-bold" :key="propertyError">{{
              fieldLabels[propertyError]
            }}</b-card-text>
            <ul :key="propertyError">
              <li :key="error" v-for="error in errors">{{ error }}</li>
            </ul>
          </template>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "ErrorViewer",
  props: {
    errors: {
      type: Object
    },
    fieldLabels: {
      type: Object
    }
  },
  computed: {
    groupedErrors() {
      return Object.keys(this.errors)
        .reduce((accumulator, currentValue) => {
          const [ lineIndex, propertyError ] = currentValue.split('.');

          if (!Object.prototype.hasOwnProperty.call(accumulator, lineIndex)) {
            accumulator[lineIndex] = {};
          }

          accumulator[lineIndex][propertyError] = this.errors[currentValue];

          return accumulator;
        }, {});
    },
  }
};
</script>

<style>
</style>
