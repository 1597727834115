export default {
  "TRT da 1ª Região - Rio de Janeiro": 1,
  "TRT da 2ª Região - São Paulo": 2,
  "TRT da 3ª Região - Minas Gerais": 3,
  "TRT da 4ª Região - Rio Grande do Sul": 4,
  "TRT da 5ª Região - Bahia": 5,
  "TRT da 6ª Região - Pernambuco": 6,
  "TRT da 7ª Região - Ceará": 7,
  "TRT da 8ª Região - Pará e Amapá": 8,
  "TRT da 9ª Região - Paraná": 9,
  "TRT da 10ª Região - Distrito Federal e Tocantins": 10,
  "TRT da 11ª Região - Amazonas e Roraima": 11,
  "TRT da 12ª Região - Santa Catarina": 12,
  "TRT da 13ª Região - Paraíba": 13,
  "TRT da 14ª Região - Acre e Rondônia": 14,
  "TRT da 15ª Região - São Paulo/Campinas": 15,
  "TRT da 16ª Região - Maranhão": 16,
  "TRT da 17ª Região - Espírito Santo": 17,
  "TRT da 18ª Região - Goiás": 18,
  "TRT da 19ª Região - Alagoas": 19,
  "TRT da 20ª Região - Sergipe": 20,
  "TRT da 21ª Região - Rio Grande do Norte": 21,
  "TRT da 22ª Região - Piauí": 22,
  "TRT da 23ª Região - Mato Grosso": 23,
  "TRT da 24ª Região - Mato Grosso do Sul": 24,
};
