<template>
  <div class="row">
    <div class="overlay d-flex align-items-center justify-content-center" v-if="loading"><b-spinner class="text-primary"></b-spinner></div>
    <div class="col-12 mb-3">
      <h5>
        <div class="form-subheader"><span>Dados Gerais</span></div>
      </h5>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Número da cotação</label>
      <p>{{ quotation.number }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Seguradora</label>
      <p>
        {{
          quotation.guarantee && quotation.guarantee.insurer
            ? quotation.guarantee.insurer.name
            : "---"
        }}
      </p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Criado em</label>
      <p>
        {{ formatDateLocal(quotation.created_at) }} 
        <a v-if="isMaster()" @click="openModalLog()"
          size="sm" variant="primary" class="btn btn-sm btn-outline-primary ml-2 text-uppercase"
          v-b-tooltip.hover title="Histórico Cotação">
          <i class="fa fa-calendar"></i>
          Histórico
        </a>
      </p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Tipo</label>
      <p>
        {{
          getEnumKeyByEnumValue(
            translateInsuranceTypes,
            quotation.insurance_type_id
          )
        }}
      </p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Subtipo</label>
      <p>
        {{
          quotation.insurance_category
            ? quotation.insurance_category.name
            : "---"
        }}
      </p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Status</label>
      <p>
        <span :class="classStatusQuotation(quotation.status)"
          @click="quotation.status == translateStatusQuotationTypes['Emitida'] ? redirectToPolicy(quotation) : null">
          {{
            getEnumKeyByEnumValue(translateStatusQuotationTypes, quotation.status)
          }}
        </span>
      </p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">CPF/CNPJ Tomador</label>
      <p>{{ quotation.document ? quotation.document : "---" }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Tomador</label>
      <p>{{ quotation.name }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Nome do usuário</label>
      <p>
        {{ quotation.user  ? quotation.user.name : ' Indisponível '  }}
        <a
          v-if="isMaster()" class="btn btn-sm btn-outline-primary ml-2 text-uppercase"
          v-b-modal.show-modal>
          <i class="fa fa-pencil-alt"></i>
          Alterar
        </a>
      </p>
    </div>

    <div v-if="existEmissionData" class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">EMPRESA EMISSORA</label>
      <p>
        {{ quotation.emissionData.name }}
      </p>
    </div>
    <div v-else></div>

    <div v-if="existEmissionData" class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">DATA DA EMISSÃO</label>
      <p>
        {{quotation.date}}
      </p>
    </div>
    <div v-else></div>

    <div class="modal">
      <b-modal
        id="show-modal"
        ref="showModal"
        size="lg"
        class="mt-4"
        hide-footer
        centered
      >
        <br />
        <template #modal-title> Alterar Usuário </template>
        <div class="input-group mb-4">
          <b-form-input
            @keyup="searchUser()"
            placeholder="Digite sua busca..."
            v-model="filter"
          ></b-form-input>
          <div class="input-group-append">
            <button class="btn btn-primary">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div class="overlay_modal" v-if="loadingModal"><b-spinner class="text-primary"></b-spinner></div>
        <div class="mb-3 list">
          <div
            v-for="user in users"
            :key="user.id"
            :value="user.id"
            class="h-100 text-left d-flex align-items-center px-3 py-2"
          >
            <div
              v-if="user.id == user_selected"
              class="
                card
                shadow
                border-1
                h-100
                w-100
                shadow-primary
                border-primary
              "
            >
              <div class="h-100 text-left d-flex align-items-center px-3 py-2">
                <h6 class="mb-1 pr-3">{{ user.name }}</h6>
                <span class="font-14 text-primary"
                  >{{ user.email }} - {{ user.document }}</span
                >
                <button
                  class="ml-auto button btn py-2 nowrap btn-outline-primary"
                >
                  <i class="fa fa-check text-primary"></i>
                  <span class="d-none d-lg-inline"> Selecionar</span>
                </button>
              </div>
            </div>

            <div v-else class="card shadow border-1 h-100 w-100">
              <div class="h-100 text-left d-flex align-items-center px-3 py-2">
                <h6 class="mb-1 pr-3">{{ user.name }}</h6>
                <span class="font-14 text-primary"
                  >{{ user.email }} - {{ user.document }}</span
                >
                <button
                  class="ml-auto button btn py-2 nowrap btn-outline-primary"
                  @click="selectUser(user.id)"
                >
                  <i class="fa fa-check text-primary"></i>
                  <span class="d-none d-lg-inline"> Selecionar</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <div class="modal">
      <b-modal
        id="show-modal-prizes-list"
        ref="showModalPrizesList"
        class="mt-4 modal-width"
        size="lg"
        centered
        hide-footer
      >
        <template #modal-title> Prêmios Retornados </template>
        <div>
          <b-table class="table pretty mb-0" :items="items" :fields="fields">
            <template #cell(icon)="data">
              <img class="ml-3" width="120" height="70" :src="data.item.icon" />
            </template>
            <template #cell(price)="data">
              <span>{{ formatCurrency(data.item.price) }}</span>
            </template>
            <template #cell(tax)="data">
              <span>{{ formatPercentage(data.item.tax) }}</span>
            </template>
            <template #cell(created_at)="data">
              <span class="mr-2">{{
                formatDateTime(data.item.created_at)
              }}</span>
            </template>
          </b-table>
        </div>
        </b-modal>
    </div>
    
    <div class="modal">
      <b-modal id="show-modal-status-change" ref="showModalStatusChange"
        size="lg" class="mt-4" hide-footer centered>
        <template #modal-title> Alterar Status </template>

        <div class="row">
          
          <div class="col-md-6 mb-3">
            <label class="dbo-ui" for="">Seguradora</label>
            <select
              v-model="dataFormStatus.insurer_selected"
              class="form-control">
              <option disabled="disabled" :value="null">Selecione...</option>
              <option
                v-for="(name, identifier) in insurers"
                :key="identifier"
                :value="identifier">
                {{ name }}
              </option>
            </select>
          </div>
          
          <div class="col-md-6 mb-3">
            <label class="dbo-ui" for="">Status</label>
            <select
              v-model="dataFormStatus.status"
              class="form-control">
              <option disabled="disabled" :value="null">Selecione...</option>
              <option
                v-for="(status, index) in options"
                :key="index"
                :value="status.value">
                {{ status.text }}
              </option>
            </select>
          </div>

        </div>
        
        <div class="row mt-3">
          <div class="col-12 col-lg-12">
            <button
              @click="$bvModal.hide('show-modal-status-change')"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1">
                <i class="fa fa-angle-left mr-2"></i>
                <span class="d-none d-md-inline-block"> Voltar</span>
            </button>
            
            <button
              @click="updateStatusQuotation()"
              class="btn btn-primary btn-lg text-uppercase px-4"
              data-toggle="modal" data-target="#show-modal-status-change">
                Confirmar
                <i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>

      </b-modal>
    </div>
    <div class="modal">
      <b-modal id="show-modal-log" ref="showModalLog"
        size="lg" class="mt-4" hide-footer centered>
        <template #modal-title> Histórico </template>

        <div class="row">

          <div class="col-12">
            <div v-if="statusLogQuotations.length > 0" class="table-logs">
              <table class="pretty admin mb-3 shadow">
                <thead>
                  <tr>
                    <th>Ação</th>
                    <th>Usuário</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(statusLogQuotation, index) in statusLogQuotations" :key="index">
                    <td>
                      {{statusLogQuotation.name}}

                      <p v-if="statusLogQuotation.cloned_quotation_id" class="m-0">                        
                        <span class="badge badge-blue_clean cursor-pointer mr-2"
                          @click="redirect('details-quotation', {quotationId: statusLogQuotation.cloned_quotation_id})">
                          Cotação de origem nº{{ statusLogQuotation.cloned_quotation ? statusLogQuotation.cloned_quotation.number : '' }}</span>

                        <span class="badge badge-primary">
                            Cotação nº{{ statusLogQuotation.quotation.number }}
                        </span>
                      </p>

                      <p v-else-if="statusLogQuotation.status_from.id != statusLogQuotation.status_to.id" class="m-0">
                        <span class="badge badge-blue_clean mr-2">De: {{ statusLogQuotation.status_from.translate }}</span>
                        <span class="badge badge-primary">Para: {{ statusLogQuotation.status_to.translate }}</span>
                      </p>

                    </td>
                    <td>{{statusLogQuotation.user.name}}</td>
                    <td>{{formatDateTimeCustom(statusLogQuotation.created_at)}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-else>
              <p>Nenhum registro encontrado.</p>
            </div>
          </div>

        </div>
        
        <div class="row mt-3">
          <div class="col-12 col-lg-12">
            <button
              @click="$bvModal.hide('show-modal-log')"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1">
                <i class="fa fa-angle-left mr-2"></i>
                <span class="d-none d-md-inline-block"> Voltar</span>
            </button>
          </div>
        </div>

      </b-modal>
    </div>
    
  </div>
</template>

<script>
import commomsHelper from "@/mixins/commomsHelper";
import dateFormatter from "@/mixins/dateFormatter";
import translateInsuranceTypes from "@/enums/translate/insuranceTypes";
import translateInsurersTypes from "@/enums/translate/insurersTypes";
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";
import commonsService from "@/services/commons";
import authenticationService from "@/services/authentication";
import userService from "@/services/user";
import quotationService from "@/services/quotation";
import numericFormatterMixin from "@/mixins/numericFormatter";

export default {
  name: "GeneralData",
  emits: ['getQuotation'],
  props: {
    quotation: {
      type: Object,
      required: true,
    },
  },
  computed:{
    existEmissionData(){
      return this.quotation.emissionData && this.quotation.emissionData.name;
    },
    rows() {
      return this.items.length;
    },
    fields() {
      var columns = [
        { key: "icon", label: "Seguradora", tdClass: "font-14"},
        { key: "price", label: "Prêmio", tdClass: "font-14" },
        { key: "tax", label: "Taxa", tdClass: "font-14" },
        { key: "created_at", label: "Data", tdClass: "font-14 ml-2", thClass: "label-custom"},
      ];
      
      return columns;
    }
  },
  mixins: [dateFormatter, commomsHelper, numericFormatterMixin],
  data: () => ({
    translateInsuranceTypes: translateInsuranceTypes,
    translateStatusQuotationTypes: translateStatusQuotationTypes,
    translateInsurersTypes: translateInsurersTypes,
    users: {},
    user_selected: null,
    dataFormStatus: {},
    filter: "",
    users_data: {},
    loading: false,
    loadingModal: false,
    items: [],
    insurers: [],
    options: [],
    statusLogQuotations: [],
  }),
  methods: {
    isMaster() {
      return authenticationService.isMaster();
    },
    getUsers() {
      userService
        .users()
        .then((response) => {
          this.users = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os usuários.");
        }).finally(() => {
          this.users_data = this.users;
          this.loadingModal = false;
        });
    },
    updateUser() {
      this.loading = true;
      quotationService
        .updateUser(this.quotation.id, this.user_selected)
        .then((response) => {
          this.$refs.showModal.hide();
          this.quotation = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível alterar o usuário.");
        })
        .finally(() => {
          this.getQuotation(this.quotation.id);
          this.loading = false;
        });
    },
    updateStatusQuotation() {
      this.loading = true;
      quotationService
        .updateStatusQuotation(this.quotation.id, this.dataFormStatus)
        .then((response) => {
          this.$refs.showModalStatusChange.hide();
          this.quotation = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível alterar o usuário.");
        })
        .finally(() => {
          this.getQuotation(this.quotation.id);
          this.loading = false;
        });
    },
    searchUser() {
      this.loadingModal = true;
      this.users = this.users_data;
      var usersObject = this.users.filter((user) => {
        if (
          user.document.includes(this.filter) ||
          user.email.toLowerCase().includes(this.filter.toLowerCase()) ||
          user.name.toLowerCase().includes(this.filter.toLowerCase())
        ) {
          return user;
        }
      });
      this.users = usersObject;
      this.loadingModal = false;

    },
    selectUser(user_id) {
      this.user_selected = user_id;
      this.$bvModal.hide("show-modal");
      this.updateUser();
    },
    getQuotation(uid){
      this.$emit('getQuotation', uid);
    },
    redirectToPolicy(quotation) {
      if (quotation.policies.length) {
        this.redirect('details-policy', {policyId: quotation.policies[0].id})
      }
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    getInsurers() {
      commonsService
        .insurers()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.insurers = { ...response.data };
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as seguradoras.");
        });
    },
    getStatusModalChange() {
      this.listStatusQuotationChange()
        .forEach((element) => {
          this.options.push({value: element, text: this.getEnumKeyByEnumValue(this.translateStatusQuotationTypes, element)})
        })
    },
    openModalLog() {
      this.statusLogQuotations = [];
      quotationService
        .getStatusLogQuotation(this.quotation.id)
        .then((response) => {
          this.statusLogQuotations = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível buscar os logs.");
        })
        .finally(() => {
          this.$bvModal.show("show-modal-log");
          this.loading = false;
        });
    },
  },
  created() {
    this.loadingModal = true;
    this.user_selected = this.quotation.user_id;
    this.items = this.quotation.prizes;
    this.dataFormStatus = {
      status : this.quotation.status,
      insurer_selected : this.quotation.guarantee.insurer_selected,
    }
    this.getUsers();
    this.getInsurers();
    this.getStatusModalChange();
  },
};
</script>

<style>
.table-logs { overflow-y: auto; height: 400px; }
.table-logs table { border-collapse: collapse; width: 100%; }
.table-logs table thead th { position: sticky; top: 0px; }
</style>
