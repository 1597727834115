<template>
  <div>
      <div v-if="alertMessage" class="col-12 w-100 px-0">
          <div v-if="this.guarantee_start < this.getCurrentDate()" @click="updateGuaranteeDate" class="alert-custom alert-danger-custom">
            <a class="text-white text-decoration-none"> {{ alertMessage }} </a>
          </div>
          <div v-else class="alert-custom alert-danger-custom">
            <a class="text-white text-decoration-none"> {{ alertMessage }} </a>
          </div>
      </div>
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form
        @submit.stop.prevent="handleSubmit($parent.quotation(dataForm))"
        class="form"
        autocomplete="off"
      >
        <section class="form">
          <div class="row" v-if="!customs">
            <div class="col-6 col-lg-6 mb-3" >
              <div
                v-if="
                  quotationCategoryId ==
                  insuranceCategoryTypes.CUSTOMS_TEMPORARY_ADMISSION
                "
              >
                <label class="dbo-ui"
                  >Número DI (Declaração de importação)
                  <i
                    class="fa fa-question-circle"
                    v-b-tooltip.hover
                    title="Informe aqui o número da declaração de importação"
                  ></i
                ></label>
                <validation-provider
                  name="Número DI (Declaração de importação)"
                  rules="required|max:25"
                  v-slot="validationContext"
                  vid="declaration_number"
                >
                  <b-form-input
                    v-model="dataForm.declaration_number"
                    :state="$parent.getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
              <div v-else>
                <label class="dbo-ui"
                  >Número TRTA
                  <i
                    class="fa fa-question-circle"
                    v-b-tooltip.hover
                    title="Informe aqui o número do termo de responsabilidade para trânsito aduaneiro"
                  ></i
                ></label>
                <validation-provider
                  name="Número TRTA (Termo de responsabilidade para trânsito aduaneiro)"
                  rules="required|max:25"
                  v-slot="validationContext"
                  vid="declaration_number"
                >
                  <b-form-input
                    v-model="dataForm.declaration_number"
                    :state="$parent.getValidationState(validationContext)"
                  ></b-form-input>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </validation-provider>
              </div>
            </div>

            <div class="col-6 col-lg-6 mb-3" v-if="quotationCategoryId == insuranceCategoryTypes.CUSTOMS_TEMPORARY_ADMISSION">
              <label class="dbo-ui"
                >Termo de responsabilidade RCR
                <i
                  class="fa fa-question-circle"
                  v-b-tooltip.hover
                  title="Informe aqui o termo de responsabilidade RCR."
                ></i
              ></label>
              <validation-provider
                name="termo de responsabilidade"
                rules="required|max:25"
                v-slot="validationContext"
                vid="contract_number"
              >
                <b-form-input
                  v-model="dataForm.contract_number"
                  :state="$parent.getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </div>

          <div class="row" v-if="customs">
            <div class="col-6 col-lg-6 mb-3">
              <label 
                class="dbo-ui"
                v-if="isInstallmentType()"
              >Número do processo administrativo 
                <i
                  class="fa fa-question-circle"
                  v-b-tooltip.hover
                  title="Informe aqui o número do processo que exige a garantia. Este campo é parte fundamental para emissão de sua apólice."
                ></i
              ></label>
              <label 
                class="dbo-ui"
                v-else
              > Número do processo
                <i
                  class="fa fa-question-circle"
                  v-b-tooltip.hover
                  title="Informe aqui o número do processo que exige a garantia. Este campo é parte fundamental para emissão de sua apólice."
                ></i
              ></label>
              <validation-provider
                name="número do processo"
                rules="required|max:25"
                v-slot="validationContext"
                vid="contract_number"
              >
                <b-form-input
                  v-mask="processMask"
                  v-model="dataForm.contract_number"
                  :state="$parent.getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div 
              class="col-12 col-lg-6 mb-3" 
              v-if="
                quotationCategoryId == insuranceCategoryTypes.TAX_ENFORCEMENT ||
                isInstallmentType()
                "
            >
              <label class="dbo-ui"
                >Certidão de Dívida Ativa (CDA) 
                <i
                  class="fa fa-question-circle"
                  v-b-tooltip.hover
                  title="Informe o número da certidão de dívida ativa."
                ></i
              ></label>
              <validation-provider
                name="cda"
                rules="required"
                v-slot="validationContext"
                vid="cda"
              >
                <b-form-input
                  v-model="dataForm.active_debt_certificate"
                  :type="typeInputStick"
                  :formatter="formatStick"
                  :state="$parent.getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </div>

          <div class="row" v-if="customs && !isInstallmentType()">
            <div v-if="checkLaborCourts" class="col-12 col-lg-6 mb-3">
              <label class="dbo-ui"
                >Tribunal
                <i
                  class="fa fa-question-circle"
                  v-b-tooltip.hover
                  title="Informe o Tribunal que o processo foi registrado"
                ></i
              ></label>
              <validation-provider name="tribunal" rules="required">
                <div slot-scope="ProviderProps" 
                  v-if="showCourtSelect()">
                  <select
                    class="form-control"
                    :class="{ 'is-invalid': ProviderProps.errors[0] }"
                    v-model="dataForm.court"
                  >
                    <option
                      v-for="(laborCourt, index) in translateLaborCourtsTypes"
                      :key="index"
                      :value="index"
                    >
                      {{ index }}
                    </option>
                  </select>
                  <p class="invalid-feedback-custom">
                    {{ ProviderProps.errors[0] }}
                  </p>
                </div>

                <div v-else-if="!showCourtSelect()">
                  <select
                    class="form-control"
                    v-if="insurance_category_id == insuranceCategoryTypes.APPEAL_A_CIVIL_ACTION"
                    :class="{ 'is-invalid': ProviderProps.errors[0] }"
                    v-model="dataForm.court"
                    @click="completeState(dataForm.court)"
                  >
                    <option
                      v-for="(justiceCourt, index) in translateJusticeCourtsTypes"
                      :key="index"
                      :value="index"
                    >
                      {{ index }}
                    </option>
                  </select>
                  <b-form-input
                    v-else
                    v-model="dataForm.court"
                    :type="typeInputStick"
                    :formatter="formatStick"
                    :state="$parent.getValidationState(ProviderProps)"
                  >
                  </b-form-input>
                  <p class="invalid-feedback-custom">
                    {{ ProviderProps.errors[0] }}
                  </p>
                </div>
              </validation-provider>
            </div>

            <div class="col-12 col-lg-6 mb-3" v-else>
              <label class="dbo-ui">Tribunal</label>
              <validation-provider name="tribunal" rules="required">
                <div slot-scope="ProviderProps">
                  <select
                    class="form-control"
                    :class="{ 'is-invalid': ProviderProps.errors[0] }"
                    v-model="dataForm.court"
                    @click="completeState(dataForm.court)"

                  >
                    <option
                      v-for="(laborCourt, index) in translateLaborCourtsTypes"
                      :key="index"
                      :value="index"
                    >
                      {{ index }}
                    </option>
                  </select>
                  <p class="invalid-feedback-custom">
                    {{ ProviderProps.errors[0] }}
                  </p>
                </div>
              </validation-provider>
            </div>
            <div class="col-12 col-lg-6 mb-3">
              <label class="dbo-ui"
                >{{ labelInputStick }}
                <i
                  class="fa fa-question-circle"
                  v-b-tooltip.hover
                  :title="toolTipText"
                ></i
              ></label>
              <validation-provider
                name="vara"
                rules="required"
                v-slot="validationContext"
                vid="stick"
              >
                <b-form-input
                  v-model="dataForm.stick"
                  :type="typeInputStick"
                  :formatter="formatStick"
                  :state="$parent.getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div class="col-12 col-lg-6 mb-3" v-if="!isInstallmentType()">
              <label class="dbo-ui">Estado da Vara</label>
              <validation-provider name="estado" rules="required">
                <div slot-scope="ProviderProps">
                  <select
                    @change="findCities(dataForm.court_state_id)"
                    class="form-control"
                    :class="{ 'is-invalid': ProviderProps.errors[0] }"
                    v-model="dataForm.court_state_id"
                  >
                    <option
                      v-for="(state, index) in states"
                      :key="index"
                      :value="state.id"
                    >
                      {{ state.name }}
                    </option>
                  </select>
                  <p class="invalid-feedback-custom">
                    {{ ProviderProps.errors[0] }}
                  </p>
                </div>
              </validation-provider>
            </div>

            <div class="col-12 col-lg-6 mb-3" v-if="!isInstallmentType()">
              <label class="dbo-ui">Cidade da Vara</label>
              <validation-provider name="cidade" rules="required">
                <div slot-scope="ProviderProps">
                  <select
                    class="form-control"
                    :class="{ 'is-invalid': ProviderProps.errors[0] }"
                    v-model="dataForm.court_city_id"
                  >
                    <option
                      v-for="(city, index) in cities"
                      :key="city"
                      :value="index"
                    >
                      {{ city }}
                    </option>
                  </select>
                  <p class="invalid-feedback-custom">
                    {{ ProviderProps.errors[0] }}
                  </p>
                </div>
              </validation-provider>
            </div>
            
            <div 
              class="col-12 col-lg-6 mb-3" 
              v-if="quotationCategoryId == insuranceCategoryTypes.TAX_ENFORCEMENT"
            >
              <label class="dbo-ui"
                >Número do processo administrativo
                <i
                  class="fa fa-question-circle"
                  v-b-tooltip.hover
                  title="Informe o número do processo administrativo."
                ></i
              ></label>
              <validation-provider
                name="processo administrativo"
                rules="required"
                v-slot="validationContext"
                vid="processo"
              >
                <b-form-input
                  v-model="dataForm.administrative_process_number"
                  :type="typeInputStick"
                  :formatter="formatStick"
                  :state="$parent.getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </div>

          <div class="row" v-if="showAdditionalNotes && customs">
            <div class="col-12 col-lg-12 mb-3">
              <label class="dbo-ui"
                >Observações
                <i
                  class="fa fa-question-circle"
                  v-b-tooltip.hover
                  title="Informe qualquer informação adicional sobre o seu pedido de garantia"
                ></i
              ></label>
              <validation-provider
                name="observações"
                v-slot="validationContext"
                vid="additional_notes"
              >
                <b-form-textarea
                  rows="3"
                  v-model="dataForm.additional_notes"
                  :state="$parent.getValidationState(validationContext)"
                ></b-form-textarea>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </div>

          <div class="row" v-if="quotationCategoryId == insuranceCategoryTypes.CUSTOMS_TEMPORARY_ADMISSION">
            <div class="col-12 col-lg-12 mb-3">
              <label class="dbo-ui"
                >Material
                <i
                  class="fa fa-question-circle"
                  v-b-tooltip.hover
                  title="Informe o tipo de material"
                ></i
              ></label>
              <validation-provider
                name="Material"
                v-slot="validationContext"
                vid="additional_notes"
              >
                <b-form-textarea
                  rows="3"
                  v-model="dataForm.additional_notes"
                  :state="$parent.getValidationState(validationContext)"
                ></b-form-textarea>
                <b-form-invalid-feedback>{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </validation-provider>
            </div>
          </div>

          <div v-show="checkConventionalFlow" class="row">
            <div class="col-12">
              <upload-data
                ref="upload"
                :files="filesJudicial"
                :url="urlUpload"
                @deleteFile="deleteFile"
                :forceRedirect="false"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-lg-12 mt-5">
              <button
                @click="$parent.back()"
                class="
                  btn btn-outline-secondary btn-lg
                  text-uppercase
                  px-4
                  mr-1
                "
              >
                <i class="fa fa-angle-left mr-2"></i
                ><span class="d-none d-md-inline-block"> Voltar</span>
              </button>
              <button
                :disabled="invalid || isRetroactive"
                class="btn btn-primary btn-lg text-uppercase px-4"
              >
                Avançar <i class="fa fa-angle-right ml-2"></i>
              </button>
              <span
                v-b-tooltip.hover
                :title="tooltipTitle"
                class="ml-2"
                v-if="invalid || isRetroactive"
              >
                <i class="fas fa-exclamation-circle text-warning"></i>
              </span>
            </div>
          </div>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { UploadData } from "@/components";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import insuranceCategoryTypes from "@/enums/insuranceCategoryTypes";
import quotationService from "@/services/quotation";
import translateLaborCourtsTypes from "@/enums/translate/laborCourtsTypes";
import translateJusticeCourtsTypes from "@/enums/translate/justiceCourtsTypes";
import commonsService from "@/services/commons";
import statesJson from "@/enums/states.json";
import commomsHelper from '@/mixins/commomsHelper';
import dateFormatter from '@/mixins/dateFormatter';
import { mask } from "vue-the-mask";

export default {
  name: "DetailsData",
  mixins: [
    commomsHelper, dateFormatter
    ],
  directives: {
    mask,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    UploadData, 
  },
  props: {
    dataJudicial: {
      type: Object,
    },
    filesJudicial: {
      type: Array,
    },
    quotationCategoryId: {
      type: Number,
    },
    urlUpload: {
      type: String,
    },
  },
  data: () => ({
    dataForm: {
      contract_number: null,
      declaration_number: null,
      court: null,
      active_debt_certificate: null,
      administrative_process_number: null,
    },
    insuranceCategoryTypes: insuranceCategoryTypes,
    translateLaborCourtsTypes: translateLaborCourtsTypes,
    translateJusticeCourtsTypes: translateJusticeCourtsTypes,
    states: null,
    cities: null,
    guarantee_start: null,
    isRetroactive: false,
    insurance_category_id: null
  }),
  computed: {
    showAdditionalNotes() {
      var categories = [
        this.insuranceCategoryTypes.MAKE_A_LABOR_APPEAL_DEPOSIT,
      ];
      return categories.indexOf(this.quotationCategoryId) == -1;
    },
    notAllowedTypes() {
      return [
        this.insuranceCategoryTypes.MAKE_A_LABOR_APPEAL_DEPOSIT,
        this.insuranceCategoryTypes.REPLACEMENT_OF_APPEAL_DEPOSIT,
      ];
    },
    allowedLaborCourts() {
      return [
        this.insuranceCategoryTypes.MAKE_A_LABOR_APPEAL_DEPOSIT,
        this.insuranceCategoryTypes.REPLACEMENT_OF_APPEAL_DEPOSIT,
        this.insuranceCategoryTypes.ENSURE_A_LABOR_EXECUTION,
      ];
    },
    customs() {
      var categories = [
        this.insuranceCategoryTypes.CUSTOMS,
        this.insuranceCategoryTypes.CUSTOMS_TRANSIT,
        this.insuranceCategoryTypes.CUSTOMS_TEMPORARY_ADMISSION,
      ];
      return categories.indexOf(this.quotationCategoryId) == -1;
    },
    typeInputStick() {
      return this.checkLaborCourts ? "text" : "number";
    },
    labelInputStick() {
      return this.checkLaborCourts ? "Vara" : "Número da Vara";
    },
    toolTipText() {
      return this.checkLaborCourts
        ? "Informe a vara que o processo foi registrado"
        : "Informe apenas o número da vara.";
    },
    checkConventionalFlow() {
      if (!this.quotationCategoryId) {
        return false;
      }
      return this.notAllowedTypes.indexOf(this.quotationCategoryId) == -1;
    },
    checkLaborCourts() {
      if (!this.quotationCategoryId) {
        return false;
      }
      return this.allowedLaborCourts.indexOf(this.quotationCategoryId) == -1;
    },
    processMask() {
      return "#######.##.####.#.##.####";
    },
  },
  created() {
    this.findQuotation(this.getIdFromUrl());
    this.findStates();
  },
  methods: {
    completeState(value){
      value = value === null ? '' : value;
      statesJson.states.forEach(element => {
        if (value.includes(element.name)) {
          this.dataForm.court_state_id = element.id;
          this.findCities(element.id);
        }
      });
    },
    formatStick(e){
     return this.checkLaborCourts ? e : String(e).substring(0,2);
    },
    fillFieldsJudicial(element) {
      if (element != "") {
        this.dataForm.contract_number = element.guarantee.contract_number;
        this.dataForm.court = element.guarantee.court;
        this.dataForm.stick = this.checkLaborCourts
          ? element.guarantee.stick
          : element.guarantee.stick.replace(/\D/g, "");
        this.dataForm.additional_notes = element.guarantee.additional_notes;
        this.dataForm.insurance_category_id = element.insurance_category_id;
        this.dataForm.resource_type = element.guarantee.resource_type;
        this.dataForm.court_state_id = element.guarantee.court_state_id;
        this.findCities(element.guarantee.court_state_id);
        this.dataForm.court_city_id = element.guarantee.court_city_id;
        this.dataForm.declaration_number = element.guarantee.declaration_number;
        this.dataForm.active_debt_certificate = element.guarantee.active_debt_certificate;
        this.dataForm.administrative_process_number = element.guarantee.administrative_process_number;
      }
    },
    deleteFile(fileId) {
      this.$parent.resetAlert();
      quotationService
        .deleteQuotationFile(this.dataJudicial.id, fileId)
        .then((response) => {
          if (response.data.error) {
            this.$parent.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.$parent.showError("Não foi possível deletar o arquivo.");
        });
    },
    async findCities(state_id) {
      await commonsService
        .cities(state_id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.cities = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as cidades.");
        });
    },
    findStates() {
      commonsService
        .states()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.states = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os estados.");
        });
    },
    setTribunal(newVal) {
      var court = null;
      if (newVal.length == 25) {
        var tribunal = newVal.substr(18, 2);
        Object.keys(this.translateLaborCourtsTypes).forEach(function (
          index,
          key
        ) {
          if (tribunal == key + 1) {
            court = index;
          }
        });
        this.completeState(court)
        this.dataForm.court = court;
      }
    },
    findQuotation(uid){
      quotationService
        .getByUID(uid)
        .then((response) => {
          this.findGuarantee(uid, response.data.insurance_category_id);
          this.insurance_category_id = response.data.insurance_category_id;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a cotação.");
        })
    },
    findGuarantee(uid) {
      quotationService
        .getGuaranteeByUID(uid)
        .then((response) => {
          this.guarantee_start = response.data.guarantee_start;
          if(response.data.guarantee_start < this.getCurrentDate() && this.isCategoryCurrentDate()){
           this.isRetroactive = true;
           this.showError("A data de início da vigência não pode ser menor que a data atual. Clique aqui para reiniciar a cotação com a data atual.");
          }
        })
    },
    updateGuaranteeDate(){
       this.$router.push({
          name: "garantee-data",
          params: { quotationId: this.getIdFromUrl() },
        });
    },
    isCategoryCurrentDate() {
      return [
        this.insuranceCategoryTypes.MAKE_A_LABOR_APPEAL_DEPOSIT,
        this.insuranceCategoryTypes.PARTICIPATE_IN_A_BID_OR_COMPETITION,
      ].some(value => value === this.quotationCategoryId);
    },
    tooltipTitle() {
       return this.isRetroactive
        ? "A data de início da vigência não pode ser menor que a data atual."
        : "Necessário preencher campos obrigatórios para avançar.";
    },
    showCourtSelect(){
      if(
          this.insurance_category_id == this.insuranceCategoryTypes.TAX_ENFORCEMENT ||
          this.insurance_category_id == this.insuranceCategoryTypes.APPEAL_A_CIVIL_ACTION
        ) {
            return false;
          }
        return true;
    },
    isInstallmentType(){
      return this.quotationCategoryId == this.insuranceCategoryTypes.I_HAVE_TAX_CREDIT_OR_INSTALLMENT;
    },
  },
  watch: {
    dataJudicial: {
      immediate: true,
      deep: true,
      handler(data) {
        this.fillFieldsJudicial(data);
      },
    },
    "dataForm.contract_number": function (newVal) {
      if (this.showCourtSelect()) {
          this.setTribunal(newVal);
      }
    },
  },
};
</script>

<style>
</style>
