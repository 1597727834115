<template>
  <div>
    <h5>
      <div class="form-subheader">
        <span>Mensagens da cotação </span>
      </div>
    </h5>
    <div v-if="loading" class="overlay d-flex align-items-center justify-content-center">
      <div class="d-flex justify-content-center mb-2">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
    <div>
      <a
        @click="$bvModal.show('show-modal-message')"
        class="button btn btn-outline-secondary btn-sm mb-3 float-right"
      >
        <i class="fa fa-plus mr-1"></i>
        Adicionar Mensagem
      </a>
    </div>
    <label class="dbo-ui mb-lg-0 font-weight-bold ml-2" for="">Observações</label>
    <b-table :fields="fields" :items="items">
      <template #cell(date)="data">
        <span class="centered-cell">{{
          data.value != "" ? formatDateTimeCustom(data.value) : "- - -"
        }}</span>
      </template>
      <template #cell(files)="data">
        <div>
          <span class="row mx-2 my-1" v-for="(file, index) in data.value" :key="index">
            {{ file.name }}
            <i class="fas fa-download icon-btn ml-2" @click="downloadFile(file)"></i>
          </span>
        </div>
      </template>
      <template #cell(text)="data"
        ><span class="centered-cell"> {{ data.value }}</span></template
      >
      <template #cell(author)="data"
        ><span class="centered-cell"> {{ data.value }}</span></template
      >
      <template #cell(actions)="row">
        <div class="centered-header">
          <a
            v-if="getUserId == row.item.user_id || isMaster()"
            class="text-secondary"
            @click="showModalDelete(row.item)"
          >
            <i class="fas fa-trash mx-1 icon-btn"></i>
          </a>

          <a
            v-if="getUserId == row.item.user_id || isMaster()"
            class="text-secondary"
            @click="updateMessage(row.item)"
          >
            <i class="fas fa-pen mx-1 icon-btn"></i>
          </a>
        </div>
      </template>
    </b-table>
    <div class="modal">
      <b-modal
        id="show-modal-delete-message"
        ref="showModalDeleteMessage"
        size="sm"
        class="mt-4"
        hide-footer
        centered
      >
        <br />
        <template #modal-title>
          <h5 class="modal-title">Deseja apagar essa mensagem ?</h5>
        </template>
        <div class="text-center font-14 dbo-ui">
          Deseja excluir a mensagem criada por {{ message_data.author }} na data
          {{ formatDateTimeCustom(message_data.date) }}
        </div>
        <button
          @click="$bvModal.hide('show-modal-delete-message')"
          class="btn btn-outline-secondary btn-lg text-uppercase my-4 px-4 mr-1"
        >
          <i class="fa fa-angle-left mr-2"></i
          ><span class="d-none d-md-inline-block"> Voltar</span>
        </button>
        <button
          @click="deleteMessage(message_data.action_id)"
          class="btn btn-primary w-50 my-4 float-right"
        >
          Excluir
          <i class="fa fa-angle-right ml-2"></i>
        </button>
      </b-modal>
    </div>
  </div>
</template>

<script>
import quotationService from "@/services/quotation";
import commomsHelperMixin from "@/mixins/commomsHelper";
import dateFormatterMixin from "@/mixins/dateFormatter";
import authenticationService from "@/services/authentication";
export default {
  name: "QuotationMessageTable",
  emits: ["setMessageData"],
  mixins: [commomsHelperMixin, dateFormatterMixin],
  data: () => ({
    items: [],
    loading: false,
    message_data: {},
  }),
  created() {
    this.listMessages();
  },
  computed: {
    fields() {
      var columns = [
        {
          key: "author",
          label: "usuário",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },

        {
          key: "text",
          label: "Mensagem",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "files",
          label: "anexos",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "date",
          label: "data",
          tdClass: "font-6",
          thClass: "table-pretty dbo-ui centered-header",
        },
        {
          key: "actions",
          label: "",
          tdClass: "font-14 ",
          thClass: "table-pretty dbo-ui centered-header",
        },
      ];
      return columns;
    },
    getUserId() {
      return this.$store.getters.user.id;
    },
  },
  methods: {
    listMessages() {
      this.loading = true;
      quotationService
        .listMessages(this.getIdFromUrl())
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.items = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível apagar arquivo da cotação");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showModalDelete(message_data) {
      this.message_data = message_data;
      this.$bvModal.show("show-modal-delete-message");
    },
    deleteMessage(message_id) {
      this.loading = true;
      quotationService
        .destroyMessage(message_id)
        .then((response) => {
          if (response.data.error) {
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível apagar mensagem.");
        })
        .finally(() => {
          this.$bvModal.hide("show-modal-delete-message");
          this.loading = false;
          this.listMessages();
        });
    },
    downloadFile(file) {
      this.loading = true;
      quotationService
        .downloadMessageFile(file.id, this.getIdFromUrl())
        .then((response) => {
          if (response.data.error) {
            return;
          }
          this.openLinkTargetBlank(response.data);
        })
        .catch(() => {
          this.showError("Não foi possível baixar o arquivo.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateMessage(message_data) {
      this.message_data = message_data;
      this.$emit("setMessageData", this.message_data);
      this.$bvModal.show("show-modal-message");
    },
    isMaster() {
      return authenticationService.isMaster();
    },
  },
};
</script>

<style></style>
