<template>
  <div class="row">
    <div class="col-12">

      <div class="row">
        <div class="col-12 mb-3">
          <h5 class="form-subheader"><span>Dados do segurado/beneficiário</span></h5>
        </div>

        <div class="col-12 col-lg-4 mb-3">
          <label class="dbo-ui" for="">CPF/CNPJ</label>
          <p>{{quotation.insured ? quotation.insured.document : '---'}}</p>
        </div>

        <div class="col-12 col-lg-4 mb-3">
          <label class="dbo-ui" for="">Nome/Razão Social</label>
          <p>{{quotation.insured ? quotation.insured.name : '---'}}</p>
        </div>

        <div class="col-12 col-lg-3 mb-3">
            <label class="dbo-ui" for="">Tipo</label>
            <p>{{quotation.insured && quotation.insured.is_public ? 'Público' : 'Privado'}}</p>
        </div> 

        <div class="col-12 col-sm-4 mb-3">
          <label class="dbo-ui" for="">CEP</label>
          <p>{{quotation.insured ? quotation.insured.zipcode : '---'}}</p>
        </div>
        <div class="col-12 col-sm-8 mb-3">
          <label class="dbo-ui" for="">Endereço</label>
          <p>{{quotation.insured ? quotation.insured.address + ', ' +
              quotation.insured.address_number + ' - ' + quotation.insured.district : '---'}}</p>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import dateFormatter from '@/mixins/dateFormatter';

export default {
  name: "InsuranceData",
  mixins: [
    dateFormatter,
  ],
  props: {
    quotation: {
      type: Object,
      required: true
    }
  }
};
</script>

<style>
</style>
