<template>
  <div class="modal">
    <b-modal
      id="show-modal-message"
      ref="showModalMessage"
      @shown="initializeUserAndDate()"
      @hidden="resetModalData()"
      size="lg"
      class="mt-4"
      hide-footer
    >
      <template #modal-title>
        <h5 class="modal-title">Adicionar uma mensagem a Cotação</h5>
      </template>
      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
        <b-form @submit.stop.prevent="handleSubmit()" class="form" autocomplete="off">
          <validation-provider
            name="texto da observação"
            rules="required"
            v-slot="validationContext"
            vid="text"
          >
            <b-form-textarea
              rows="7"
              cols="70"
              :value="message_data.text"
              v-model="message_data.text"
            ></b-form-textarea>
            <b-form-invalid-feedback>{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </validation-provider>
          <upload-data
            :files="files"
            :url="url"
            @deleteFile="deleteFile"
            @upload-finished="clearData()"
            ref="upload"
          />
          <button
            v-if="is_edit"
            :disabled="invalid"
            @click="updateMessageData()"
            class="btn btn-primary ml-6 my-2 float-right"
          >
            Salvar
          </button>
          <button
            v-else
            :disabled="invalid"
            @click="saveMessageData()"
            class="btn btn-primary ml-6 my-2 float-right"
          >
            Salvar
          </button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import commomsHelperMixin from "@/mixins/commomsHelper";
import quotationService from "@/services/quotation";
import UploadData from "./UploadData.vue";

export default {
  name: "MessageModal",
  mixins: [commomsHelperMixin],
  emits: ["setMessageData", "showTableData", "showLoading"],
  components: {
    UploadData,
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    url: "",
    files: [],
    action_id: null,
    is_edit: false,
    loading: false,
    uploadFinished: false,
    message_data: {
      text: "",
      author: "",
      date: "",
    },
  }),
  props: {
    quotation_message_data: {
      type: Object,
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    initializeUserAndDate() {
      if (this.quotation_message_data.action_id != null) {
        this.files = this.quotation_message_data.files;
        this.is_edit = true;
        this.showMessageData();
      }
      this.message_data.author = this.$store.getters.user.name;
      this.message_data.date = new Date().toISOString().split("T")[0];
    },
    saveMessageData() {
      this.$emit("showLoading", true);
      quotationService
        .storeMessage(this.getIdFromUrl(), this.message_data)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.uploadMessageFile(response.data[0]);
        })
        .catch(() => {
          this.showError("Não foi possível apagar arquivo da cotação");
        })
        .finally(() => {
          if (this.uploadFinished) {
            this.$emit("showTableData");
            this.uploadFinished = false;
            this.$bvModal.hide("show-modal-message");
            this.$emit("showLoading", false);
          }
        });
    },
    showMessageData() {
      this.$emit("showLoading", true);
      quotationService
        .showMessage(this.quotation_message_data.action_id, this.message_data)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.message_data.text = response.data.text;
        })
        .catch(() => {
          this.showError("Não foi possível atualizar a mensagem da cotação");
        })
        .finally(() => {
          this.$emit("showLoading", false);
        });
    },
    updateMessageData() {
      this.$emit("showLoading", true);
      this.uploadMessageFile(this.quotation_message_data.action_id);
      quotationService
        .updateMessage(this.quotation_message_data.action_id, this.message_data)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível atualizar as mensagens da cotação");
        })
        .finally(() => {
          this.$emit("showLoading", false);
          this.$bvModal.hide("show-modal-message");
          this.$emit("showTableData");
        });
    },
    uploadMessageFile(quotation_message_id) {
      this.url = `/api/v1/quotations/${this.getIdFromUrl()}/upload-message-file/${quotation_message_id}`;
      this.$nextTick(() => {
        const uploads = Array.isArray(this.$refs.upload)
          ? this.$refs.upload
          : [this.$refs.upload];
        uploads.forEach((element) => {
          if (element.$refs.dropzone.getAcceptedFiles().length > 0) {
            element.$refs.dropzone.processQueue();
            return;
          } else {
            this.uploadFinished = true;
          }
        });
      });
    },
    deleteFile(fileId) {
      if (fileId) {
        this.$emit("showLoading", true);
        quotationService
          .deleteMessageFile(fileId, this.getIdFromUrl())
          .then((response) => {
            if (response.data.error) {
              this.showError(response.data.message);
              return;
            }
          })
          .catch(() => {
            this.showError("Não foi possível deletar o arquivo.");
          })
          .finally(() => {
            this.$emit("showLoading", false);
            this.$emit("showTableData");
          });
      }
    },
    resetModalData() {
      this.$emit("setMessageData", {});
      this.is_edit = false;
      this.message_data.text = "";
      this.files = [];
    },
    clearData() {
      this.$emit("showLoading", false);
      this.$bvModal.hide("show-modal-message");
      this.$emit("showTableData");
      this.message_data = {};
      this.files = [];
    },
  },
};
</script>

<style></style>
