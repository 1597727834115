<template>
  <div class="row">
    <div class="col-12 mb-3">
      <h5>
        <div class="form-subheader"><span>Dados do contrato</span></div>
      </h5>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Modalidade</label>
      <p>
        {{
          quotation.guarantee && quotation.guarantee.contract_type
          ? getEnumKeyByEnumValue(
            translateContractTypes,
            quotation.guarantee.contract_type
          )
          : "---"
        }}
      </p>
    </div>

    <div class="col-12 col-lg-4 mb-3" v-if="!showJudicialFields">
      <label class="dbo-ui" for="">Valor previsto no contrato</label>
      <p>
        {{
          formatCurrency(
            quotation.guarantee && quotation.guarantee.contract_value
              ? quotation.guarantee.contract_value
              : 0
          )
        }}
      </p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Percentual coberto seguro garantia</label>
      <p v-if="!showJudicialFields">
        {{
          formatPercentage(
            quotation.guarantee && quotation.guarantee.guarantee_percent
              ? quotation.guarantee.guarantee_percent
              : 0
          )
        }}
      </p>
      <p v-else>{{ formatPercentage(percentageJudicial) }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Valor garantia</label>
      <p v-if="showJudicialFields">
        {{ formatCurrency(quotation.guarantee ? judicialContractValue : 0) }}
      </p>
      <p v-else>{{ formatCurrency(guaranteeValue) }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Nº do contrato</label>
      <p>
        {{
          quotation.guarantee && quotation.guarantee.contract_number
          ? quotation.guarantee.contract_number
          : "---"
        }}
      </p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Objeto do contrato</label>
      <p>
        {{
          quotation.guarantee && quotation.guarantee.guarantee_object
          ? quotation.guarantee.guarantee_object
          : "---"
        }}
      </p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Prêmio</label>
      <p>
        {{
          quotation.price
          ? formatCurrency(quotation.price)
          : "---"
        }}
      </p>
    </div>

    <div class="col-12 mb-3">
      <h5 class="form-subheader"><span>Dados do seguro</span></h5>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Iníco da vigência</label>
      <p>
        {{
          quotation.guarantee && quotation.guarantee.guarantee_start
          ? formatDateLocal(quotation.guarantee.guarantee_start)
          : "---"
        }}
      </p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Período da cobertura (em dias)</label>
      <p>
        {{
          quotation.guarantee && quotation.guarantee.days_count
          ? quotation.guarantee.days_count + " dias"
          : "---"
        }}
      </p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Término da vigência</label>
      <p>
        {{
          quotation.guarantee && quotation.guarantee.guarantee_end
          ? formatDateLocal(quotation.guarantee.guarantee_end)
          : "---"
        }}
      </p>
    </div>

    <template v-if="showJudicialFields">
      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Tribunal</label>
        <p>
          {{
            quotation.guarantee && quotation.guarantee.court
            ? quotation.guarantee.court
            : "---"
          }}
        </p>
      </div>

      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Vara</label>
        <p>
          {{
            quotation.guarantee && quotation.guarantee.stick
            ? quotation.guarantee.stick
            : "---"
          }}
        </p>
      </div>
      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Estado da Vara</label>
        <p>
          {{
            quotation.guarantee && quotation.guarantee.state
            ? quotation.guarantee.state
            : "---"
          }}
        </p>
      </div>
      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Cidade da vara</label>
        <p>
          {{
            quotation.guarantee && quotation.guarantee.city
            ? quotation.guarantee.city
            : "---"
          }}
        </p>
      </div>

      <div v-if="quotation.guarantee && quotation.guarantee.resource_type" class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Tipo de Recurso</label>
        <p>
          {{
            getEnumKeyByEnumValue(
              translateResourceTypes,
              quotation.guarantee.resource_type
            )
          }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import numericFormatterMixin from "@/mixins/numericFormatter";
import commomsHelper from "@/mixins/commomsHelper";
import dateFormatter from "@/mixins/dateFormatter";
import translateContractTypes from "@/enums/translate/contractTypes";
import translateResourceTypes from "@/enums/translate/resourceTypes";
import insuranceCategoryTypes from "@/enums/insuranceCategoryTypes";

export default {
  name: "ContractData",
  props: {
    quotation: {
      type: Object,
      required: true,
    },
  },
  mixins: [numericFormatterMixin, dateFormatter, commomsHelper],
  computed: {
    showJudicialFields() {
      return (
        this.quotation.insurance_category.parent_insurance_category_id ==
        this.insuranceCategoryTypes.I_HAVE_A_LAWSUIT
      );
    },
    judicialContractValue() {
      return this.quotation.guarantee
        ? this.quotation.guarantee.contract_value
        : 0;
    },
    guaranteeValue() {
      return this.quotation.guarantee
        ? this.quotation.guarantee.guarantee_value
        : 0;
    },
  },
  data: () => ({
    translateContractTypes: translateContractTypes,
    translateResourceTypes: translateResourceTypes,
    insuranceCategoryTypes: insuranceCategoryTypes,
    percentageJudicial: 30,
  }),
};
</script>

<style></style>
