import http from "@/services/http";

const store = (dataForm) => http.post("quotations", dataForm)
const get = (params) => http.get(`quotations`, { params: params })
const update = (uid, dataForm) => http.patch(`quotations/${uid}`, dataForm)
const updateStatusQuotation = (uid, dataForm) => http.patch(`quotations/${uid}/update-status-quotation`, dataForm)
const updateUser = (uid, user_id) => http.patch(`quotations/${uid}/update-user`, { user_id: user_id })
const getByUID = (uid) => http.get(`quotations/${uid}`)
const getGuaranteeByUID = (uid) => http.get(`quotations/${uid}/guarantee`)
const generatePolicy = (uid, termId) => http.post(`quotations/${uid}/policy/${termId}`)
const getInsuredByUID = (uid) => http.get(`quotations/${uid}/insured`)
const getQuotationFiles = (uid) => http.get(`quotations/${uid}/files`)
const downloadFile = (uid, fileId) => http.get(`quotations/${uid}/files/${fileId}`, { responseType: 'blob' })
const deleteQuotationFile = (uid) => http.delete(`quotations/${uid}/files`)
const updateGuarantee = (uid, dataForm) => http.patch(`quotations/${uid}/guarantee`, dataForm)
const updateInsured = (uid, dataForm) => http.patch(`quotations/${uid}/insured`, dataForm)
const getInsurers = (uid) => http.get(`quotations/${uid}/insurers`)
const getInsurerQuotation = (insurerQuotationUrl) => http.get(insurerQuotationUrl)
const conclusionQuotation = (uid) => http.post(`quotations/${uid}/conclusion`, [])
const destroy = (uid) => http.delete(`quotations/${uid}`)
const clone = (uid) => http.post(`quotations/${uid}/clone`)
const requestGeneratePolicy = (uid) => http.post(`quotations/${uid}/request-policy`)
const emitQuotation = (uid) => http.post(`quotations/${uid}/emit-policy`)
const returnToQueue = (uid) => http.post(`quotations/${uid}/return-to-queue`)
const getStatusLogQuotation = (uid) => http.get(`quotations/${uid}/status-log`)

const getCertificatesFiles = (identifier) => http.get(`certificates/${identifier}/files`)
const deleteInsurerFile = (identifier, fileId) => http.delete(`certificates/${identifier}/files/${fileId}`)
const downloadInsurerFile = (identifier, fileId) => http.get(`certificates/${identifier}/files/${fileId}`)
const getFailedQuote = (uid, insurerIdentifier) => http.get(`quotations/${uid}/failed-quote/${insurerIdentifier}`)
const quotationIssueResend = (uid) => http.post(`quotations/${uid}/quotation-issue-resend`)
const getKanbanData = (params) => http.get(`kanban`, { params: params })

const listMessages = (uid) => http.get(`quotations/${uid}/list-messages`)
const showMessage = (id) => http.get(`quotations/${id}/show-message`)
const storeMessage = (uid, dataForm) => http.post(`quotations/${uid}/store-message`, dataForm)
const updateMessage = (id, dataForm) => http.patch(`quotations/${id}/update-message`, dataForm)
const destroyMessage = (id) => http.delete(`quotations/${id}/delete-message`)
const downloadMessageFile = (id, quotation_id) => http.get(`quotations/${id}/download-message-file/${quotation_id}`)
const deleteMessageFile = (id, quotation_id) => http.delete(`quotations/${id}/delete-message-file/${quotation_id}`)
const generateReport = (uid) => http.get(`quotations/${uid}/generate-report`)

export default {
  store,
  update,
  get,
  getByUID,
  updateGuarantee,
  getInsuredByUID,
  updateInsured,
  getGuaranteeByUID,
  generatePolicy,
  deleteQuotationFile,
  getQuotationFiles,
  getInsurers,
  getInsurerQuotation,
  conclusionQuotation,
  downloadFile,
  destroy,
  clone,
  updateUser,
  updateStatusQuotation,
  deleteInsurerFile,
  downloadInsurerFile,
  getCertificatesFiles,
  requestGeneratePolicy,
  emitQuotation,
  getFailedQuote,
  returnToQueue,
  quotationIssueResend,
  getKanbanData,
  listMessages,
  showMessage,
  storeMessage,
  updateMessage,
  destroyMessage,
  downloadMessageFile,
  deleteMessageFile,
  generateReport,
  getStatusLogQuotation
}
