<template>
  <div class="row">
    <div class="col-12 mb-3">
      <h5><div class="form-subheader"><span>Documentos anexos</span></div></h5>
    </div>

    <div class="col-12 col-lg-12 mb-3">
      <table class="pretty admin mb-3 shadow">
        <thead>
          <tr>
            <th>Nome do documento</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="file in files" :key="file.id">
            <td>{{file.name}}</td>
            <td>
              <button @click="openLinkTargetBlank(file.url)" type="button" class="button btn btn-primary btn-sm">
                <i class="fa fa-download"></i> Download </button>
              <button @click="showModalDestroy(file)" type="button" class="button btn  btn-outline-primary btn-sm ml-2">
                <i class="fa fa-times"></i> Excluir </button>
            </td>
          </tr>
          <tr v-if="proposal">
            <td>{{quotation.guarantee.proposal_downloaded_file}}</td>
            <td>
              <button @click="openLinkTargetBlank(quotation.guarantee.proposal_url)" type="button" class="button btn btn-primary btn-sm">
                <i class="fa fa-download"></i> Download </button>
              <button @click="showModalDestroy(quotation.guarantee)" type="button" class="button btn  btn-outline-primary btn-sm ml-2">
                <i class="fa fa-times"></i> Excluir</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  <div class="modal">
      <b-modal
        id="show-modal-delete"
        ref="showModalDelete"
        size="sm"
        class="mt-4"
        hide-footer
        centered
      >
        <br />
        <template #modal-title>
          Excluir arquivo
        </template>

        <div class="card card-termo-uso mt-1 mb-5">
          <p class="my-4">Deseja realmente excluir o arquivo {{ file.name }} ?</p>
        </div>
        <div class="row">
          <div class="col-12 col-lg-12">
            <button
              @click="$bvModal.hide('show-modal')"
              class="btn btn-outline-secondary btn-lg text-uppercase px-4 mr-1"
            >
              <i class="fa fa-angle-left mr-2"></i
              ><span class="d-none d-md-inline-block"> Voltar</span>
            </button>
            <button
              @click="deleteFiles(file)"
              class="btn btn-primary btn-lg text-uppercase px-4"
              data-toggle="modal"
              data-target="#modal-cnpj"
            >
              Excluir<i class="fa fa-angle-right ml-2"></i>
            </button>
          </div>
        </div>
      </b-modal>
    </div>


  </div>
</template>

<script>
import commomsHelper from '@/mixins/commomsHelper';
import quotationService from "@/services/quotation";

export default {
  name: "DocumentsData",
  emits: ['deleteFiles'],
  data: () => ({
    file : {},
    files : []
  }),
  computed: {
    proposal() {
      return this.quotation && this.quotation.guarantee && this.quotation.guarantee.proposal_url
    }
  },
  mixins: [
    commomsHelper
  ],
  created() {
    this.findQuotationAndPoliciesFiles(this.quotation.id);
  },
  methods: {
    deleteFiles(file){
      this.$emit('deleteFiles', file);
    },
    showModalDestroy(file) {
      this.$refs.showModalDelete.show();
      this.file = file;
    },
    findQuotationAndPoliciesFiles(uid) {
      quotationService
        .getQuotationFiles(uid)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.files = response.data
        })
        .catch(() => {
          console.error('Unable to retrieve policy files.');
        });
    },
  },
  props: {
    quotation: {
      type: Object,
      required: true
    }
  },
};
</script>

<style>
</style>
