export default {
  'Junto Seguros': 'junto-seguros',
  'Essor': 'essor',
  'Excelsior': 'excelsior',
  'Tokio Marine': 'tokio-marine',
  'JNS': 'jns',
  'Newe': 'newe',
  'Fator': 'fator',
  'Ezze': 'ezze',
  'Sombrero': 'sombrero',
  'Pottencial': 'pottencial',
  'Avla': 'avla',
  'BMG': 'bmg',
  'Berkley': 'berkley'
}
