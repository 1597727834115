<template>
  <div class="row">

    <div class="col-12 mb-3">
      <h5><div class="form-subheader"><span>Sócio(s)</span></div></h5>
    </div>

    <div class="row col-12" v-for="(row, index) in quotation.partner" :key="index">
      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Tipo de Sócio</label>
        <p>{{ getEnumKeyByEnumValue(translatePartnerTypes, row.type) }}</p>
      </div>
      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Nome Completo/Razão Social</label>
        <p>{{row.name}}</p>
      </div>
      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">CPF/CNPJ</label>
        <p>{{row.document}}</p>
      </div>
    </div>

  </div>
</template>

<script>
import commomsHelper from '@/mixins/commomsHelper';
import translatePartnerTypes from '@/enums/translate/partnerTypes';

export default {
  name: "Partner",
  props: {
    quotation: {
      partner: Array
    }

  },
  mixins: [
    commomsHelper
  ],
  data: () => ({
    translatePartnerTypes: translatePartnerTypes,
  }),
};
</script>

<style>
</style>
