<template>
  <div class="row">
    <div class="col-12 mb-3">
      <h5>
        <div class="form-subheader">
          <span>Dados do Imóvel Pretendido</span>
        </div>
      </h5>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">CEP</label>
      <p>{{ validateData(quotation.coverage_guarantee.zipcode) }}</p>
    </div>

    <div class="col-12 col-lg-8 mb-9">
      <label class="dbo-ui" for="">Endereço</label>
      <p>{{ validateData(quotation.coverage_guarantee.address) }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Bairro</label>
      <p>{{ validateData(quotation.coverage_guarantee.district) }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Cidade</label>
      <p>{{ cities ? cities[quotation.coverage_guarantee.city_id] : "---" }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Estado</label>
      <p>{{ states ? validateData(states.name) : "---" }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Número</label>
      <p>{{ validateData(quotation.coverage_guarantee.number) }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Complemento</label>
      <p>{{ validateData(quotation.coverage_guarantee.complement) }}</p>
    </div>
  </div>
</template>

<script>
import commonsService from "@/services/commons";

export default {
  name: "CoverageGuarantee",
  props: {
    quotation: {
      coverage_guarantee: Object,
    },
  },
  data: () => ({
    states: null,
    cities: null,
  }),
  created() {
    this.findStates();
    this.findCities(this.quotation.coverage_guarantee.state_id);
  },
  methods: {
    findStates() {
      commonsService
        .states()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.states = response.data.find(
            (element) =>
              element.id == this.quotation.coverage_guarantee.state_id
          );
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os estados.");
        });
    },
    findCities(state_id) {
      commonsService
        .cities(state_id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.cities = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as cidades.");
        });
    },
    validateData(val) {
      return val || "---";
    },
  },
};
</script>

<style></style>
