<template>
  <div class="row">
    <div class="col-12 mb-3">
      <h5>
        <div class="form-subheader"><span>Dados do Pretendente</span></div>
      </h5>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Número da cotação</label>
      <p>{{ quotation.number }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Tipo do Seguro</label>
      <p>
        {{
          getEnumKeyByEnumValue(
            translateInsuranceTypes,
            quotation.insurance_type_id
          )
        }}
      </p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">Status</label>
      <p>
        {{
          getEnumKeyByEnumValue(translateStatusQuotationTypes, quotation.status)
        }}
      </p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">CEP do Endereço Atual</label>
      <p>
        {{
          quotation.suitor_guarantees.zipcode
            ? quotation.suitor_guarantees.zipcode
            : "---"
        }}
      </p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">{{ checkNameCpfOrCnpj }}</label>
      <p>{{ quotation.document ? quotation.document : "---" }}</p>
    </div>

    <div class="col-12 col-lg-4 mb-3">
      <label class="dbo-ui" for="">{{ checkNameOrCorporate }}</label>
      <p>{{ quotation.name }}</p>
    </div>

    <template v-if="checkLegalPerson">
      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Finalidade da Locação</label>
        <p>
          {{
            getEnumKeyByEnumValue(
              translateFinalityTypes,
              quotation.suitor_guarantees.tax_option
            )
          }}
        </p>
      </div>

      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Ramo de Atividade</label>
        <p>
          {{
            getEnumKeyByEnumValue(
              translateDataActivityTypes,
              quotation.suitor_guarantees.branch_activity
            )
          }}
        </p>
      </div>

      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Faturamento Mensal Médio</label>
        <p>
          {{
            quotation.suitor_guarantees
              ? formatCurrency(
                  quotation.suitor_guarantees.average_monthly_billing
                )
              : "---"
          }}
        </p>
      </div>

      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Opção Tributária</label>
        <p>
          {{
            getParamEnumObjByEnumKey(translateTaxTypes, checkTaxTypes, "title")
          }}
        </p>
      </div>

      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Telefone para contato</label>
        <p>
          {{
            quotation.suitor_guarantees
              ? quotation.suitor_guarantees.contact_phone
              : "---"
          }}
        </p>
      </div>

      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Telefona da Empresa</label>
        <p>
          {{
            quotation.suitor_guarantees
              ? quotation.suitor_guarantees.company_phone
              : "---"
          }}
        </p>
      </div>
    </template>

    <template v-else>
      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Estado civil</label>
        <p>
          {{
            getEnumKeyByEnumValue(
              translateMaritalStatusTypes,
              quotation.suitor_guarantees.marital_status
            )
          }}
        </p>
      </div>

      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Data de nascimento</label>
        <p>{{ formatDateLocal(quotation.suitor_guarantees.birthdate) }}</p>
      </div>
    </template>

    <div
      class="col-12 col-lg-4 mb-3"
      v-if="quotation.suitor_guarantees.occupant_name"
    >
      <label class="dbo-ui" for="">Nome da Real Ocupante</label>
      <p>{{ quotation.insurance_category.name }}</p>
    </div>

    <div
      class="col-12 col-lg-4 mb-3"
      v-if="quotation.suitor_guarantees.occupant_document"
    >
      <label class="dbo-ui" for="">CPF</label>
      <p>{{ quotation.suitor_guarantees.occupant_document }}</p>
    </div>

    <div class="col-12">
      <label class="dbo-ui" for="">Observação</label>
      <p>
        {{
          quotation.suitor_guarantees.comments
            ? quotation.suitor_guarantees.comments
            : "---"
        }}
      </p>
    </div>

    <div class="col-12">
      <hr />
      <label class="dbo-ui" for="">{{ checkPartnerOrSuitor }}</label>
     
      <div class="row mt-2" v-for="(item, index) in quotation.partner" :key="index">
        <div class="col-6">
          <label class="dbo-ui" for="" v-if="item.document.length < 15">Nome</label>
          <label class="dbo-ui" for="" v-else>Razão Social</label>
          <p>{{ item.name }}</p>
        </div>
        <div class="col-6">
          <label class="dbo-ui" for="" v-if="item.document.length < 15">CPF</label>
          <label class="dbo-ui" for="" v-else>CNPJ</label>
          <p>{{ item.document }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commomsHelper from "@/mixins/commomsHelper";
import dateFormatter from "@/mixins/dateFormatter";
import translateInsuranceTypes from "@/enums/translate/insuranceTypes";
import taxTypes from "@/enums/taxTypes";
import translateTaxTypes from "@/enums/translate/taxTypes";
import translateFinalityTypes from "@/enums/translate/finalityTypes";
import translateDataActivityTypes from "@/enums/translate/dataActivityTypes";
import numericFormatterMixin from "@/mixins/numericFormatter";
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";
import maritalStatusTypes from "@/enums/translate/maritalStatusTypes";

export default {
  name: "GeneralData",
  props: {
    quotation: {
      type: Object,
      required: true,
    },
  },
  mixins: [dateFormatter, commomsHelper, numericFormatterMixin],
  data: () => ({
    translateInsuranceTypes: translateInsuranceTypes,
    translateTaxTypes: translateTaxTypes,
    translateFinalityTypes: translateFinalityTypes,
    translateDataActivityTypes: translateDataActivityTypes,
    translateStatusQuotationTypes: translateStatusQuotationTypes,
    translateMaritalStatusTypes: maritalStatusTypes,
    taxTypes: taxTypes,
  }),
  computed: {
    checkTaxTypes() {
      return this.getEnumKeyByEnumValue(
        taxTypes,
        this.quotation.suitor_guarantees.tax_option
      );
    },
    checkPartnerOrSuitor() {
      return this.quotation.document.length < 15
        ? "Pretendentes"
        : "Sócios";
    },
    checkLegalPerson() {
      return this.quotation.document.length > 15;
    },
    checkNameCpfOrCnpj() {
      return this.quotation.document.length < 15 ? "CPF" : "CNPJ";
    },
    checkNameOrCorporate() {
      return this.quotation.document.length < 15 ? "Nome" : "Razão social";
    },
  },
  methods: {
    getParamEnumObjByEnumKey(myEnum, enumValue, param) {
      let key = Object.keys(myEnum).filter((x) => x == enumValue);
      return key.length > 0 ? myEnum[key[0]][param] : null;
    },
    checkNamePartnerOrSuitor(val) {
      return val < 15 ? "Nome" : "Razão social";
    },
  },
};
</script>

<style>
</style>
