export default {
  'Seguro Garantia': 1,
  'Seguro Fiança Locatícia': 2,
  'Seguro Risco de Engenharia':  3,
  'Seguro Responsabilidade Civil': 4,  
  'Aeronáutico': 5,
  'Auto': 6,
  'Seguro Empresarial/ Patrimonial': 7,
  'Gerenciamento de Risco': 8,
  'RD Equipamentos': 9,
  'Agronegócios': 10,
  'Construtor Naval': 11,
  'Cibernético': 12,
  'Operador Portuário': 13,
  'Náutico': 14,
  'Riscos Diversos': 15,
  'Seguro de Vida': 16,
  'Transporte': 17,
  'Residencial': 18,
  'Saúde': 19,
  'Odontológico': 20,
  'Outros': 21
}
