const taxTypes = Object.freeze({
  NATIONAL_SIMPLE : {
    id: 1,
    title: "Simples Nacional",
    text: "Extrato de faturamento extraído do sistema de cálculo do Simples Nacional referente aos dois últimos anos; <br />Guias de DAS do Simples Nacional dos últimos 6 meses, acompanhadas dos comprovantes de pagamento;<br />DASN (Declaração Anual do Simples Nacional) na íntegra com protocolo de entrega e extrato de faturamento emitido pelo simples nacional dos 02 últimos anos."
  },
  PRESUME_PROFIT : {
    id: 2,
    title: "Lucro Presumido",
    text: "ECF (Escrituração Contábil Fiscal) dos dois últimos exercícios, na integra; <br />EFD Contribuições (Escrituração Fiscal Digital) SPED - página de protocolo e página de consolidação das operações por CST (Resumo das Receitas) dos últimos seis meses, se empresa com recolhimento na fonte ou isenção tributária; <br />Darfs de Cofins (2172) dos últimos seis meses, com comprovantes de pagamento."
  },
  REAL_PROFIT : {
    id: 3,
    title: "Lucro Real",
    text: "Balanço Completo ECD/SPED (acumulado) referente aos dois últimos anos, com Ativo, Passivo e DRE (Demonstração do Resultado do Exercício) acumulado e conciliado, assinados pelo contador e representante legal;<br />Balancete Completo até o último mês disponível, com Ativo, Passivo e DRE (Demonstração do Resultado do Exercício) acumulado e conciliado, assinados pelo contador e representante legal."
  }
});

export default taxTypes;